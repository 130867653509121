import { apiCall } from "./apiservice";
import Methods from "./methods.json";


class SupportApi {

  static createSupportQuery(data) {
    return new Promise((resolve, reject) => {
      apiCall(`Support/`, Methods.POST, data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  }
}

export default SupportApi;