import React from "react";
import "./floatinglabelinput.css";
import { validateNumber } from "../common";
import Hide from '../../assets/svgs/hide.svg';
import Show from '../../assets/svgs/show.svg';

class FloatingLabelInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevValue: "",
      isActive:
        this.props.value || typeof this.props.value === "number" ? true : false,
      showPassword: false
    };

  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevValue) {
      state.prevValue = props.value;
      state.isActive =
        props.value || typeof props.value === "number" ? true : false;
    }
    return state;
  }

  onClickEyeIcon() {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleTextChange(value) {
    let isActive = false;
    if (value !== "") {
      isActive = true;
    }
    if (this.state.isActive !== isActive) {
      this.setState({ isActive });
    }
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
  }

  onPressEnterKey() {
    if (this.props.onPressEnterKey) {
      this.props.onPressEnterKey()
    }
  }
  renderChildrenView() {
    return <div className={"fi_childrenView"}>{this.props.children}</div>;
  }

  renderErrorMessageView() {
    return (
      <div className="fi_errorMessageView">
        <label className="fi_errorMessageStyle">
          {this.props.errorMessage}
        </label>
      </div>
    );
  }

  renderNumberInputView() {
    const { showRupeeSymbol, value, showPercentage, boxStyle, disabled } = this.props;
    const showSymbol = (value || value === 0) && showRupeeSymbol ? true : false;
    return (
      <>
        {showSymbol && <h6 className="fi_rupeeSymbol">{"₹"}</h6>}
        <input
          value={value}
          style={{
            paddingLeft: showSymbol ? 40 : 20,
            background: showPercentage ? "rgba(239, 183, 65, 0.07)" : "#FFFFFF",
            borderColor: showPercentage ? "#EFB741" : "",
            ...boxStyle,
          }}
          disabled={disabled}
          type={"text"}
          className={this.state.isActive ? "ActiveInputBox" : ""}
          onChange={(e) => {
            // not allowing non-numeric characters from the input value
            const isValid = validateNumber(e.target.value);
            if (isValid) {
              this.handleTextChange(e.target.value);
            }
          }}
        />
        {showPercentage && <h6 className="fi_percentageSymbol">{"%"}</h6>}
      </>
    );
  }

  renderTextInputView() {
    const { value, type, max, disabled } = this.props;
    const maxLength = this.props.maxLength ? this.props.maxLength : undefined;
    const displayType = type === 'password' ? this.state.showPassword ? 'text' : 'password' : type;
    return (
      <input
        value={value}
        type={displayType}
        max={max}
        maxLength={maxLength}
        className={this.state.isActive ? "ActiveInputBox" : ""}
        onChange={(e) => this.handleTextChange(e.target.value)}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.target.value.length >= maxLength && e.keyCode !== 8 && e.keyCode !== 46) {
            e.preventDefault();
          }

          if (e.key === "Enter") {
            this.onPressEnterKey();
          }
          
        }}
      />
    );
  }

  renderInputView() {
    const { showRupeeSymbol, showPercentage } = this.props;
    return showRupeeSymbol || showPercentage
      ? this.renderNumberInputView()
      : this.renderTextInputView();

  }

  renderSelectView() {
    return (
      <select
        value={this.props.value}
        onChange={(e) => this.handleTextChange(e.target.value)}
        className={this.props.className}
        disabled={this.props.disabled ? this.props.disabled : false}
      >
        {this.props.selectionOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  renderEyeIconView() {
    const { showPassword } = this.state;
    const icon = showPassword ? Hide : Show;
    const label = showPassword ? 'hide' : 'show';
    return (
      <div className="fi_childrenView" onClick={this.onClickEyeIcon.bind(this)}>
        <img src={icon} alt={label} />
      </div>
    );
  }

  render() {
    return (
      <div className="fi_container">
        <div id="float-label">
          {this.props.selectionView
            ? this.renderSelectView()
            : this.renderInputView()}
          <label
            id={"title"}
            className={this.state.isActive ? "Active" : ""}
            htmlFor={this.props.label}
          >
            {this.props.label}
          </label>
          {this.props.children && this.renderChildrenView()}
          {this.props.type === 'password' && this.renderEyeIconView()}
        </div>
        {/* {this.props.showErrorMessage&&this.renderErrorMessageView()} */}
      </div>
    );
  }
}

export default FloatingLabelInput;
