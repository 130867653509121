import { useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import * as Bl from '../../../../businesslogic';
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';
import RRScoreDialler from './rrscoredialler/rrscoredialler';
import { getFormatAmount } from '../../../common';

const RRScoreModal = (props) => {
  const [state, setState] = useState({
    currentAge: '',
    retirementAge: '',
    currMonExp: '',
    mliBalance: 0,
    mliAnnualContribution: 0,
    mliEquityPercentage: 0,
    nmliBalance: 0,
    nmliAnnualContribution: 0,
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
    resultObj: {},
    showResultModal: false
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const onChangeCurrentAge = (value) => {
    setState((prevState) => ({
      ...prevState,
      currentAge: Number(value)
    }))
  }

  const onChangeRetirementAge = (value) => {
    setState((prevState) => ({
      ...prevState,
      retirementAge: Number(value)
    }))
  }

  const onChangeMliBalance = (value) => {
    setState((prevState) => ({
      ...prevState,
      mliBalance: Number(value)
    }))
  }

  const onChangeMliAnnualContribution = (value) => {
    setState((prevState) => ({
      ...prevState,
      mliAnnualContribution: Number(value)
    }))
  }

  const onChangeMliEquityPercentage = (event) => {
    const newValue = event.target.value;
    setState((prevState) => ({
      ...prevState,
      mliEquityPercentage: newValue ? Number(newValue) : newValue,
    }))
  }

  const onChangeNmliBalance = (value) => {
    setState((prevState) => ({
      ...prevState,
      nmliBalance: Number(value)
    }))
  }

  const onChangeNmliAnnualContribution = (value) => {
    setState((prevState) => ({
      ...prevState,
      nmliAnnualContribution: Number(value)
    }))
  }

  const onChangeCurrentMonthExp = (value) => {
    setState((prevState) => ({
      ...prevState,
      currMonExp: Number(value)
    }))
  }

  const onClickRetakeAssessment = () => {
    setState((prevState) => ({
      ...prevState,
      currentAge: '',
      retirementAge: '',
      currMonExp: '',
      mliBalance: '',
      mliAnnualContribution: '',
      mliEquityPercentage: 0,
      nmliBalance: '',
      nmliAnnualContribution: '',
      resultObj: {},
      showResultModal: false
    }))
  }

  const onPressCalculate = () => {
    const params = `current_age=${state.currentAge}&retirnment_age=${state.retirementAge}&current_monthly_expense=${state.currMonExp}&mli_balance=${state.mliBalance}&mli_annual_contribution=${state.mliAnnualContribution}&mli_equity_percentage=${state.mliEquityPercentage}&nmli_balance=${state.nmliBalance}&nmli_annualcontribution=${state.nmliAnnualContribution}`
    console.log({ params })
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    Bl.ScoreCalculation.calculateRRScore(params).then(result => {
      setState((prevState) => ({
        ...prevState,
        resultObj: result,
        showProgress: false,
        showResultModal: true
      }))
    }).catch((error) => {
      console.log('onPressCalculate:', error)
      setState((prevState) => ({
        ...prevState,
        showProgress: false
      }))
    })
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    type
  ) => {
    return (
      <div className="rr_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          type={type}
        />
      </div>
    );
  };

  const renderHeaderView = (label) => {
    return (
      <div className='rr_headerView'>
        <label className='rr_headingStyle'>{label}</label>
        <img className='rr_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderSliderView = () => {
    return (
      <div className='rr_sliderOuterView'>
        <label className='rr_subLabelStyle'>{t('Equity Percentage in Market Linked Investments')}</label>
        <div className='rr_sliderView'>
          <Slider
            value={state.mliEquityPercentage}
            min={0}
            max={100}
            markStep={20}
            handleChange={onChangeMliEquityPercentage}
            showAge={false}
            showPercentage={true}
          />
        </div>
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='rr_inputsContentView'>
        <div className='rr_rowStyle'>
          <div className='rr_colStyle'>
            <label className='rr_labelStyle'>{t('Personal Information')}</label>
            <div className='rr_inputsRowView'>
              {renderInputView(t('Current Age'), state.currentAge, onChangeCurrentAge, false, false, 'number')}
              {renderInputView(t('Retirement Age'), state.retirementAge, onChangeRetirementAge, false, false, 'number')}
            </div>
          </div>
          <div className='rr_colStyle'>
            <label className='rr_labelStyle'>{t('Current monthly expense (Household + Health care)')}</label>
            <div className='rr_inputsRowView'>
              {renderInputView(t('Current Monthly Expense'), state.currMonExp, onChangeCurrentMonthExp, true, false)}
            </div>
          </div>
        </div>
        <div className='rr_rowStyle'>
          <div className='rr_colStyle'>
            <label className='rr_labelStyle'>{t('Mutual funds/ Stocks/ NPS etc. (Market Linked Investments)')}</label>
            <div className='rr_inputsRowView'>
              {renderInputView(t('Amount Saved So Far'), state.mliBalance, onChangeMliBalance, true, false)}
              {renderInputView(t('Average Yearly Contribution'), state.mliAnnualContribution, onChangeMliAnnualContribution, true, false)}
              {renderSliderView()}
            </div>
          </div>
          <div className='rr_colStyle'>
            <label className='rr_labelStyle'>{t('Fixed deposit/ Govt.scheme/ EPF/ PPF/ any other fixed income')}</label>
            <div className='rr_inputsRowView'>
              {renderInputView(t('Amount Saved So Far'), state.nmliBalance, onChangeNmliBalance, true, false)}
              {renderInputView(t('Average Yearly Contribution'), state.nmliAnnualContribution, onChangeNmliAnnualContribution, true, false)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='rr_buttonView'>
        <Button buttonName={t('Calculate')} onPressButton={onPressCalculate} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'rr_formContent'}>
        {renderHeaderView(t('Calculate Retirement Readiness Score'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

  const renderCorpusScoreView = () => {
    const result = state?.resultObj;
    const corpusValue = result?.COR ? getFormatAmount(result?.COR) : 0;
    const requiredAmt = result?.AmountRequired ? getFormatAmount(result?.AmountRequired) : 0;
    const corpusPercent = result?.COR ? Number((result.COR).toString()?.slice(0, 2)) : 0;
    const requiredPercent = result?.AmountRequired ? Number((result.AmountRequired).toString()?.slice(0, 2)) : 0;
    return (
      <div className='rr_corpusContent'>
        <div className='rr_labelNsliderView'>
          <label className='rr_labelStyle'>{t('Corpus required')}</label>
          <div className='rr_amtSliderView'>
            <div className='rr_amtSliderStyle' style={{ width: `${corpusPercent}%` }}></div>
            <label className='rr_amtTextStyle'>{corpusValue}</label>
          </div>
        </div>
        <div className='rr_labelNsliderView'>
          <label className='rr_labelStyle'>{t('Expected value of existing investments')}</label>
          <div className='rr_amtSliderView'>
            <div className='rr_amtSliderStyle' style={{ width: `${requiredPercent}%` }}></div>
            <label className='rr_amtTextStyle'>{requiredAmt}</label>
          </div>
        </div>
      </div>
    );
  }

  const renderDiallerView = () => {
    const result = state?.resultObj;
    const score = Number((result.RRScore * 100).toFixed(2));
    return (
      <div className={'rr_scoreContent'}>
        <RRScoreDialler
          value={score}
        />
      </div>
    );
  }

  const renderRetakeView = () => {
    return (
      <div className='rr_retakeView' onClick={onClickRetakeAssessment}>
        <label className='rr_retakeStyle'>{'Retake Assessment'}</label>
      </div>
    );
  }

  const renderResultContent = () => {
    return (
      <div className={'rr_resultContent'}>
        {renderHeaderView(t('Retirement Rediness Score'))}
        <label className='rr_labelStyle'>{t('Corpus')}</label>
        {renderCorpusScoreView()}
        <label className='rr_labelStyle'>{t('Your Retirement Readiness Score')}</label>
        {renderDiallerView()}
        {renderRetakeView()}
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={state.showResultModal ? 'rr_resultContainer' : 'rr_container'}
    >
      <div className={'rr_content'}>
        {state.showResultModal ?
          renderResultContent() :
          renderFormContent()
        }
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default RRScoreModal;