import { useRef, useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './index.css';
import {
  ProgressDialog,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';

const reports = [
  {
    receivedFrom: 'Directly from Investors',
    lastMonthPending: 0,
    currentMonthPending: 0,
    received: 0,
    resolved: 0,
    totalPending: 0,
    threeMonthsPending: 0
  },
  {
    receivedFrom: 'SEBI SCORES',
    lastMonthPending: 0,
    currentMonthPending: 0,
    received: 0,
    resolved: 0,
    totalPending: 0,
    threeMonthsPending: 0
  },
  {
    receivedFrom: 'Other Sources (If any)',
    lastMonthPending: 0,
    currentMonthPending: 0,
    received: 0,
    resolved: 0,
    totalPending: 0,
    threeMonthsPending: 0
  }
]
const ReportModal = (props) => {
  const gridRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [state, setState] = useState({
    rowData: reports,
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const columnDefs = [
    {
      headerName: 'Received From',
      field: 'receivedFrom',
      minWidth: 350,
      resizable: true,
    },
    {
      headerName: 'Pending at the end of last month',
      field: 'lastMonthPending',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Pending at the end of last week',
      field: 'currentMonthPending',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Received',
      field: 'received',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Resolved',
      field: 'resolved',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Total Pending',
      field: 'totalPending',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Pending Complaints > 3',
      field: 'threeMonthsPending',
      minWidth: 100,
      resizable: true,
    }
  ]

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }


  const renderHeaderView = (label) => {
    return (
      <div className='rm_headerView'>
        <label className='rm_headingStyle'>{label}</label>
        <img className='rm_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderTableView = () => {
    return (
      <div className='rm_tableView'>
        <div className="ag-theme-alpine" style={{ height: 400, width: 950 }}>
          <AgGridReact
            ref={gridRef}
            rowData={reports}
            columnDefs={columnDefs}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            onGridReady={params => {
              params.api.sizeColumnsToFit();
            }}
          >
          </AgGridReact>
        </div>
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'rm_content'}>
        {renderHeaderView(t('Complaint Report'))}
        {renderTableView()}
      </div>
    )
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      dialogClassName={'rm_container'}
      size={"md"}
    >
      <div>
        {renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default ReportModal;