import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Popover, Whisper } from "rsuite";
import './index.css';
import * as logger from '../../utils/logger';
import Logo from '../../../assets/svgs/websvgs/logoDark.svg';
import Goal from '../../../assets/svgs/websvgs/goal.svg';
import Insurance from '../../../assets/svgs/websvgs/insurance.svg';
import Investment from '../../../assets/svgs/websvgs/investment.svg';
import Tax from '../../../assets/svgs/websvgs/tax.svg';
import Estate from '../../../assets/svgs/websvgs/estate.svg';
import Diversified from '../../../assets/svgs/websvgs/diversifiedMgnt.svg';
import Strategic from '../../../assets/svgs/websvgs/strategicAsset.svg';
import Cash from '../../../assets/svgs/websvgs/cashMgnt.svg';
import Family from '../../../assets/svgs/websvgs/family.svg';
import SingleWindow from '../../../assets/svgs/websvgs/singleWindows.svg';
import AnnualReview from '../../../assets/svgs/websvgs/annualMgnt.svg';
import InsuranceScore from '../../../assets/svgs/websvgs/insuranceScore.svg';
import RetirementScore from '../../../assets/svgs/websvgs/retirementScore.svg';
import SustainabilityScore from '../../../assets/svgs/websvgs/sustainabilityScore.svg';
import AboutUs from '../../../assets/svgs/websvgs/aboutUs.svg';
import LinkedIn from '../../../assets/svgs/websvgs/linkedin.svg';
import Team_1 from '../../../assets/images/profile_1.JPG';
import Team_2 from '../../../assets/images/profile_2.JPG';
import LogoLight from '../../../assets/svgs/websvgs/logoLight.svg';
import Whatsapp from '../../../assets/svgs/websvgs/whatsapp.svg';
import Email from '../../../assets/svgs/websvgs/email.svg';
import Location from '../../../assets/svgs/websvgs/location.svg';
import Facebook from '../../../assets/svgs/websvgs/fb.svg';
import Insta from '../../../assets/svgs/websvgs/insta.svg';
import Twitter from '../../../assets/svgs/websvgs/twitter.svg';
import YouTube from '../../../assets/svgs/websvgs/youtube.svg';
import LinkedInLight from '../../../assets/svgs/websvgs/linkedinLight.svg';
import { Button } from "../../components";
import RRScoreModal from "../scoremodals/rrscore";
import SustainabilityScoreModal from "../scoremodals/sustainabilityscore";
import Testimonials from "../testimonials";
import Banners from "../banners";
import EnquiryModal from "../supportmodals/enquirymodal";
import ReportModal from "../supportmodals/reportmodal";
import ContactModal from "../supportmodals/contactmodal";
import InsuranceScoreModal from "../scoremodals/insurancescore";
import { resetSelectedUserDetail, resetUserCashLadderDetail, resetUserDetail } from "../../common";

const WebPageScrn = (props) => {
  // logger.fnStart()
  const [state, setState] = useState({
    showRRScoreModal: false,
    showSustainScoreModal: false,
    showEnquiryModal: false,
    showReportModal: true,
    showContactModal: false,
    showInsuranceScoreModal: false
  });
  let supportViewRef = useRef(null);
  const introViewRef = useRef(null);
  const serviceViewRef = useRef(null);
  const aboutViewRef = useRef(null);

  useEffect(() => {

    //When admin came out from the user screens,remove selectedUserDetail from localstorage
    resetUserDetail();
    resetSelectedUserDetail();
    resetUserCashLadderDetail();

    setTimeout(() => {
      hideReportModal();
    }, 5000);
  }, [])

  const onClickHome = () => {
    if (introViewRef?.current) {
      introViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const onClickService = () => {
    if (serviceViewRef?.current) {
      serviceViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const onClickAboutUs = () => {
    if (aboutViewRef?.current) {
      aboutViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const onClickSupport = () => {

  }

  const onPressClientLogin = () => {
    props.navigate("/loginscrn")
  }

  const onPressGetInTouch = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: true
    }));
  }

  const onPressGetStarted = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: true
    }));
  }

  const onPressInsuranceScore = () => {
    setState((prevState) => ({
      ...prevState,
      showInsuranceScoreModal: true
    }));
  }

  const onPressRRScore = () => {
    setState((prevState) => ({
      ...prevState,
      showRRScoreModal: true
    }));
  }

  const onPressSustainScore = () => {
    setState((prevState) => ({
      ...prevState,
      showSustainScoreModal: true
    }));
  }

  const onSelectEnquiry = () => {
    hidePopOver();
    setState((prevState) => ({
      ...prevState,
      showEnquiryModal: true
    }))
  }

  const onSelectReport = () => {
    hidePopOver();
    setState((prevState) => ({
      ...prevState,
      showReportModal: true
    }))
  }

  const hideReportModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReportModal: false
    }))
  }

  const hideEnquiryModal = () => {
    setState((prevState) => ({
      ...prevState,
      showEnquiryModal: false
    }))
  }

  const hideContactModal = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: false
    }))
  }

  const hideInsuranceScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showInsuranceScoreModal: false
    }))
  }

  const hideRRScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showRRScoreModal: false
    }))
  }

  const hideSustainScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showSustainScoreModal: false
    }))
  }

  const hidePopOver = () => {
    if (supportViewRef) {
      supportViewRef.close();
    }
  }

  const renderLogoView = () => {
    return (
      <div className={"wp_logoView"}>
        <img src={Logo} alt={'Logo'} className={"wp_logoStyle"} />
      </div>
    );
  }

  const renderNavLinkView = (label, onChange) => {
    return (
      <div
        className={"wp_navLinkView"}
        onClick={onChange}
      >
        <label className={"wp_navLinkStle"}>{label}</label>
      </div>
    );
  }

  const renderPopOverView = () => {
    return (
      <Popover className="wp_popoverView" onClose={hidePopOver}>
        <div className="wp_popupItemView" onClick={onSelectEnquiry}>
          <label className="wp_popupItemStyle">{t('Customer Enquiries')}</label>
        </div>
        <div className="wp_popupItemView" onClick={onSelectReport}>
          <label className="wp_popupItemStyle">{t('Complaint Report')}</label>
        </div>
      </Popover>
    );
  }

  const renderPopupView = () => {
    return (
      <Whisper
        placement="bottom"
        speaker={renderPopOverView()}
        trigger="click"
        ref={(ref) => (supportViewRef = ref)}
      >
        <label className={"wp_navLinkStle"}>{t('Support')}</label>
      </Whisper>
    );
  }

  const renderNavigationLinks = () => {
    return (
      <div className="wp_navView">
        {renderNavLinkView(t('Home'), onClickHome)}
        {renderNavLinkView(t('Our Services'), onClickService)}
        {renderNavLinkView(t('About us'), onClickAboutUs)}
        {renderPopupView()}
      </div>
    );
  }

  const renderLinkView = () => {
    return (
      <div className="wp_linkView" onClick={onPressClientLogin}>
        <label className={"wp_linkStyle"}>
          {t('Client Login')}
        </label>
      </div>
    );
  }

  const renderButtonView = (buttonName, onPressButton) => {
    return (
      <Button
        buttonName={buttonName}
        onPressButton={onPressButton}
      />
    );
  }

  const renderLinkNButtonView = () => {
    return (
      <div className="wp_linkNbuttonView">
        {renderLinkView()}
        {renderButtonView(t('Get In Touch'), onPressGetInTouch)}
      </div>
    );
  }

  const renderHeaderView = () => {
    return (
      <div className={"wp_headerView"}>
        {renderLogoView()}
        {renderNavigationLinks()}
        {renderLinkNButtonView()}
      </div>
    );
  }

  const renderBannersView = () => {
    return (
      <div
        ref={(ref) => introViewRef.current = ref}
        className="wp_bannersView"
      >
        <Banners
          onPressGetStarted={onPressGetStarted}
        />
      </div>
    );
  }

  const renderServiceItemView = (source, label) => {
    return (
      <div className="wp_itemView">
        <img className="wp_itemStyle" src={source} alt={label} />
        <label className="wp_itemTextStyle">{label}</label>
      </div>
    );
  }

  const renderFinancialContentView = () => {
    return (
      <div className="wp_financialContentView">
        <label className="wp_serviceSubHeadingStyle">{t('Financial Planning')}</label>
        <label className="wp_serviceSubLabelStyle">{t('powered by Fliber Investment Advisor')}</label>
        <label className="wp_serviceLabelStyle">
          {t('About Financial Planning')}
        </label>
        <div className="wp_itemsOuterView">
          {renderServiceItemView(Goal, t('Goal Planning'))}
          {renderServiceItemView(Insurance, t('Insurance Planning'))}
          {renderServiceItemView(Investment, t('Investment Planning'))}
          {renderServiceItemView(Tax, t('Tax Planning'))}
          {renderServiceItemView(Estate, t('Estate Planning'))}
          {renderServiceItemView(Estate, t('Estate Planning'))}
        </div>
      </div>
    );
  }

  const renderWealthContentView = () => {
    return (
      <div className="wp_wealthContentView">
        <label className="wp_serviceSubHeadingStyle">{t('Wealth Management')}</label>
        <label className="wp_serviceSubLabelStyle">{t('powered by Fliber Wealth')}</label>
        <label className="wp_serviceLabelStyle">
          {t('About Wealth Management')}
        </label>
        <div className="wp_itemsOuterView">
          {renderServiceItemView(Diversified, t('Diversified Investment Management & Analysis'))}
          {renderServiceItemView(Strategic, t('Strategic Asset Planning'))}
          {renderServiceItemView(Cash, t('Cash Management'))}
          {renderServiceItemView(Family, t('Family Philanthrophy'))}
          {renderServiceItemView(SingleWindow, t('Single Windows'))}
          {renderServiceItemView(AnnualReview, t('Annual Management Review'))}
        </div>
      </div>
    );
  }

  const renderServicesView = () => {
    return (
      <div
        ref={(ref) => serviceViewRef.current = ref}
        className="wp_servicesView">
        <label className="wp_serviceTitleStyle">{t('Our Services')}</label>
        <div className="wp_serviceContentView">
          {renderFinancialContentView()}
          {renderWealthContentView()}
        </div>
      </div>
    );
  }

  const renderTestItemView = (source, title, desc, link, style, onClick) => {
    return (
      <div className={style} onClick={onClick}>
        <img className="wp_testItemStyle" src={source} alt={title} />
        <label className="wp_itemTitleStyle">{title}</label>
        <label className="wp_itemDescStyle">{desc}</label>
        <label className="wp_itemLinkStyle">{link}</label>
      </div>
    );
  }

  const renderTestScoreView = () => {
    return (
      <div className="wp_testScoreView">
        <label className="wp_testTitleStyle">{t('Test your Score')}</label>
        <div className="wp_testContentsView">
          {renderTestItemView(
            InsuranceScore,
            t('Insurance Coverage'),
            t('Determine how financially secure the people close to you would be in times of emergencies'),
            t('Calculate your Insurance score'),
            'wp_testItemView_1',
            onPressInsuranceScore
          )}
          {renderTestItemView(
            RetirementScore,
            t('Retirement Readyness Score'),
            t('Check if you are financially ready to start the second innings of your life'),
            t('Calculate Retirement readiness score'),
            'wp_testItemView_2',
            onPressRRScore
          )}
          {renderTestItemView(
            SustainabilityScore,
            t('Sustainability Score'),
            t('Analyze if your retirement corpus is good enough for a comfortable retired life.'),
            t('Calculate your Sustainability Score'),
            'wp_testItemView_3',
            onPressSustainScore
          )}
        </div>
      </div>
    );
  }

  const renderAboutUsView = () => {
    return (
      <div
        ref={(ref) => aboutViewRef.current = ref}
        className="wp_aboutUsView">
        <label className="wp_aboutTitleStyle">{t('About Us')}</label>
        <div className="wp_aboutContentView">
          <div className="wp_aboutDescView">
            <label className="wp_aboutDescStyle">
              {'Fliber® is the registered trademark of Lookinglaz Technologies LLP, a SEBI Registered Investment Advisor and AMFI registered Mutual Fund Distributor. At Lookinglaz we help individuals and families to plan their financial journey and achieve their goals with personalized & unbiased advice in the most simplified manner. Our curated advisory services are backed by 50+ years of collective industry experience and our unique advanced technological tools.'}
            </label>
            <label className="wp_aboutDescStyle">
              {'We aim to become your most trusted partner in every stage of your financial journey by providing fair and affordable wealth management solutions.Our effective approach to financial planning is bred by transparency and simplicity and equips our customers with proactive unbiased advice to achieve their financial freedom'}
            </label>
            <label className="wp_aboutDescStyle">
              {'What’s More! You can ride on the most technologically advanced and revolutionary financial tool developed by us to help you visualize your financial journey in its entirety'}
            </label>
          </div>
          <img src={AboutUs} alt="About us" />
        </div>
      </div>
    );
  }

  const renderLeaderView = (source, name, link, desc) => {
    return (
      <div className="wp_leaderView">
        <div className="wp_leaderImgView">
          <img src={source} alt={name} className="wp_leaderImgStyle"></img>
        </div>
        <div className="wp_nameView">
          <label className="wp_nameStyle">{name}</label>
          <img src={LinkedIn} alt={'Linked in'} className="wp_linkedinStyle"></img>
        </div>
        <label className="wp_nameDescStyle">{desc}</label>
      </div>
    );
  }

  const renderTeamView = () => {
    return (
      <div className="wp_teamView">
        <label className="wp_teamTitleStyle">{t('Our Leadership Team')}</label>
        <div className="wp_teamContentView">
          {renderLeaderView(
            Team_1,
            t('Vikram Biswas (CFP)'),
            '',
            t('About Vikram'))
          }
          {renderLeaderView(
            Team_2,
            t('Maninder Pal Singh (CFP)'),
            '',
            t('About Manindar'))
          }
        </div>
      </div>
    );
  }

  const renderTestimonialsView = () => {
    return (
      <div className="wp_testimonialsView">
        <label className="wp_testimonialTitleStyle">{t('Testimonials')}</label>
        <div className="wp_testimonialContentView">
          <Testimonials />
        </div>
      </div>
    );
  }

  const renderContactTopView = () => {
    return (
      <div className="wp_contactTopView">
        <div className="wp_logoNcompanyView">
          <img src={LogoLight} alt={"logo"} className="wp_logoLightStyle" />
          <label className="wp_companyStyle">{t('Fliber Contact Info')}</label>
        </div>
      </div>
    );
  }
  const renderContactItemView = (source, label, value) => {
    return (
      <tr className="wp_contactRowStyle">
        <td>
          <pre>
            <img src={source} alt={label} className="wp_contactIconStyle" />
          </pre>
        </td>
        <td>
          <pre className={"wp_contactLableStyle"}>{label}</pre>
        </td>
        <td>
          <pre>{' : '}</pre>
        </td>
        <td>
          <pre className={"wp_contactValueStyle"}>{value}</pre>
        </td>
      </tr>
    );
  }
  const renderContactDetailsView = () => {
    const address = `#551, 2nd Floor,\n 14th Main, Sector – 7,\n HSR Layout , Bangalore – 560102.`;
    return (
      <div className="wp_contactDetailsView">
        <label className="wp_contactTitleStyle">{t('Contact')}</label>
        <div className="wp_contactItemView">
          <table>
            <tbody>
              {renderContactItemView(Whatsapp, t('Call & Whatsapp'), '+91 9886767218, \n+91 9379906251')}
              {renderContactItemView(Email, t('Email Address'), 'info@fliber.in')}
              {renderContactItemView(Location, t('Address'), address)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  const renderFollowUsItemView = (source, label, link) => {
    return (
      <div className="wp_followItemView">
        <img src={source} alt={label} className="wp_followIconStyle" />
        <label className="wp_followLabelStyle">{label}</label>
      </div>
    );
  }

  const renderFollowUsView = () => {
    return (
      <div className="wp_followView">
        <label className="wp_followTitleStyle">{t('Follow us')}</label>
        <div className="wp_followContentView">
          {renderFollowUsItemView(Facebook, t('Facebook'), '')}
          {renderFollowUsItemView(Insta, t('Instagram'), '')}
          {renderFollowUsItemView(Twitter, t('Twitter'), '')}
          {renderFollowUsItemView(YouTube, t('Youtube'), '')}
          {renderFollowUsItemView(LinkedInLight, t('Linkedin'), '')}
        </div>
      </div>
    );
  }

  const renderContactView = () => {
    return (
      <div className="wp_contactView">
        {renderContactTopView()}
        <div className="wp_separatorStyle"></div>
        <div className="wp_contactBottomView">
          {renderContactDetailsView()}
          {/* <div className="wp_verticalSeparatorStyle"></div>
          {renderFollowUsView()}
          <div className="wp_extraView" /> */}
        </div>
      </div>
    );
  }

  const renderFooterView = () => {
    return (
      <div className={'wp_footerView'}>
        <label className={'wp_footerTextStyle'}>{t('Copyright Info')}</label>
      </div>
    );
  }

  const renderContentView = () => {
    return (
      <div className="wp_content">
        {renderHeaderView()}
        {renderBannersView()}
        {renderServicesView()}
        {renderTestScoreView()}
        {renderAboutUsView()}
        {renderTeamView()}
        {renderTestimonialsView()}
        {renderContactView()}
        {renderFooterView()}
      </div>
    );
  }

  const renderEnquiryModal = () => {
    return (
      <EnquiryModal
        showModal={state.showEnquiryModal}
        hideModal={hideEnquiryModal}
      />
    );
  }

  const renderReportModal = () => {
    return (
      <ReportModal
        showModal={state.showReportModal}
        hideModal={hideReportModal}
      />
    );
  }

  const renderInsuranceScoreModal = () => {
    return (
      <InsuranceScoreModal
        showModal={state.showInsuranceScoreModal}
        hideModal={hideInsuranceScoreModal}
      />
    );
  }


  const renderRRScoreModal = () => {
    return (
      <RRScoreModal
        showModal={state.showRRScoreModal}
        hideModal={hideRRScoreModal}
      />
    );
  }

  const renderSustainScoreModal = () => {
    return (
      <SustainabilityScoreModal
        showModal={state.showSustainScoreModal}
        hideModal={hideSustainScoreModal}
      />
    );
  }

  const renderContactModal = () => {
    return (
      <ContactModal
        showModal={state.showContactModal}
        hideModal={hideContactModal}
      />
    );
  }

  return (
    <div className={"wp_container"}>
      {renderContentView()}
      {state.showEnquiryModal && renderEnquiryModal()}
      {state.showReportModal && renderReportModal()}
      {state.showInsuranceScoreModal && renderInsuranceScoreModal()}
      {state.showRRScoreModal && renderRRScoreModal()}
      {state.showSustainScoreModal && renderSustainScoreModal()}
      {state.showContactModal && renderContactModal()}
    </div>
  );
}

export default WebPageScrn;