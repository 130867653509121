import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './supportenquirymodal.css';
import Button from './button';
import FloatingLabelInput from './floatinglabelinput';
import ProgressDialog from './progressdialog';
import Toast from './toast';
import Close from '../../assets/svgs/ic_close.svg';
import Success from "../../assets/svgs/successmodal.svg";
import * as Bl from "../../businesslogic";
import { SUPPORT_TYPE } from '../constants';
import { getUserDetails } from '../common';

const SupportEnquiryModal = (props) => {
  const [state, setState] = useState({
    panNumber: '',
    query: '',
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
  });
  const isRenderedAlready = useRef(false);

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      getUserData();
    }
  }, [])

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const onChangePanNumber = (value) => {
    setState((prevState) => ({
      ...prevState,
      panNumber: value
    }))
  }

  const onChangeQuery = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      query: value
    }))
  }

  const checkIsDataValid = () => {
    let isValid = true;
    let toastMessage = '';

    if (state.query?.length === 0) {
      isValid = false;
      toastMessage = 'Please enter your Query';
    }

    return { isValid, toastMessage };
  }

  const onPressSend = () => {
    const isDataValid = checkIsDataValid();
    if (isDataValid.isValid) {
      const data = {
        "mobile": "",
        "email": "",
        "query": state.query,
        "pan": state.panNumber,
        "supporttype": SUPPORT_TYPE.CLIENT,
        "status": "Progress"
      }

      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }))

      Bl.Support.createSupportQuery(data).then(response => {
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          query: '',
          showResponseView: true
        }))
      }).catch(error => {
        console.log('SupportEnquiryModal/ onPressSend', error)
        showToast(error.message)
      })
    } else {
      showToast(isDataValid.toastMessage)
    }
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const getUserData = () => {
    const userData = getUserDetails();
    setState((prevState) => ({
      ...prevState,
      panNumber: userData.pannumber
    }))
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    type
  ) => {
    return (
      <div className="sem_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          type={type}
        />
      </div>
    );
  };

  const renderHeaderView = (label) => {
    return (
      <div className='sem_headerView'>
        <label className='sem_headingStyle'>{label}</label>
        <img className='sem_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderTextAreaView = (label, value, onChange) => {
    return (
      <div className='sem_textAreaView'>
        <label className='sem_textLabelStyle'>{label}</label>
        <textarea
          value={value}
          onChange={onChange}
          className='sem_textAreaStyle'
        />
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='sem_inputsContentView'>
        <label className='sem_labelStyle'>{t('Our friendly team would love to hear from you!')}</label>
        {renderTextAreaView(t('Write us your query'), state.query, onChangeQuery)}
        <label className='sem_subLabelStyle'>{t('contact us info')}</label>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='sem_buttonView'>
        <Button buttonName={t('Send')} onPressButton={onPressSend} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'sem_formContent'}>
        {renderHeaderView(t('Client Enquiries'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

  const renderResponseView = () => {
    return (
      <div className={"sem_responseContentView"}>
        {renderHeaderView(t('Customer Enquiries'))}
        <div className={"sem_responseView"}>
          <img src={Success} alt={"success"} className={"sem_successStyle"} />
          <label className={"sem_responseStyle"}>{t("Thank you for submitting your quiries, An our advisor from Fliber will get back to you soon.")}</label>
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={'sem_container'}
    >
      <div className='sem_content'>
        {state.showResponseView ? renderResponseView() : renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default SupportEnquiryModal;