import { useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import * as Bl from '../../../../businesslogic';
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';
import { getFormatAmount } from '../../../common';

const InsuranceScoreModal = (props) => {
  const [state, setState] = useState({
    annualIncExp: 'Income',
    annualIncExpAmt: '',
    expectIncExp: 'Income',
    expectIncExpPercentage: 0,
    loanAmt: '',
    noYears: '',
    lifeInsAmt: '',
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
    resultObj: {},
    showResultModal: false
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const onChangeAnnualIncExp = (value) => {
    setState((prevState) => ({
      ...prevState,
      annualIncExp: value,
      expectIncExp: value,
      showResultModal: false
    }))
  }

  const onChangeAnnualIncExpAmt = (value) => {
    setState((prevState) => ({
      ...prevState,
      annualIncExpAmt: Number(value),
      showResultModal: false
    }))
  }

  const onChangeExpectIncExp = (value) => {
    setState((prevState) => ({
      ...prevState,
      expectIncExp: value,
      showResultModal: false
    }))
  }

  const onChangeExpectIncExpAmt = (event) => {
    const newValue = event.target.value;
    setState((prevState) => ({
      ...prevState,
      expectIncExpPercentage: newValue ? Number(newValue) : newValue,
      showResultModal: false
    }))
  }

  const onChangeLoanAmt = (value) => {
    setState((prevState) => ({
      ...prevState,
      loanAmt: Number(value),
      showResultModal: false
    }))
  }

  const onChangeLifeInsAmt = (value) => {
    setState((prevState) => ({
      ...prevState,
      lifeInsAmt: Number(value),
      showResultModal: false
    }))
  }

  const onChangeNoofYears = (value) => {
    setState((prevState) => ({
      ...prevState,
      noYears: Number(value),
      showResultModal: false
    }))
  }

  const onPressCalculate = () => {
    const params = `key=349568ea-fd26-4b61-b219-1b3c1cdaf786&annual_income_expense=${state.annualIncExpAmt}&expected_increase_income_expense=${state.expectIncExpPercentage}&loan_amount=${state.loanAmt}&no_years=${state.noYears}`
    console.log({ params })
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    Bl.ScoreCalculation.calculateInsuranceScore(params).then(result => {
      setState((prevState) => ({
        ...prevState,
        resultObj: result,
        showProgress: false,
        showResultModal: true
      }))
    }).catch((error) => {
      console.log('onPressCalculate:', error)
      setState((prevState) => ({
        ...prevState,
        showProgress: false
      }))
    })
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    type,
    isDisabled = false
  ) => {
    return (
      <div className="insm_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          type={type}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const renderSelectInputView = (
    label,
    value,
    onChange,
    options,
    isDisabled = false
  ) => {
    return (
      <div className="insm_input">
        <FloatingLabelInput
          label={label}
          value={value}
          selectionView={true}
          selectionOptions={options}
          onChangeValue={onChange}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const renderHeaderView = (label) => {
    return (
      <div className='insm_headerView'>
        <label className='insm_headingStyle'>{label}</label>
        <img className='insm_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderSliderView = () => {
    return (
      <div className='insm_sliderOuterView'>
        <label className='insm_labelStyle'>{t('Annual Percentage Increase of Income/ Expense')}</label>
        <div className='insm_sliderView'>
          <Slider
            value={state.expectIncExpPercentage}
            min={0}
            max={100}
            markStep={20}
            handleChange={onChangeExpectIncExpAmt}
            showAge={false}
            showPercentage={true}
          />
        </div>
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='insm_inputsContentView'>
        <div className='insm_inputsRowView'>
          {renderSelectInputView(
            t('Annual Income/Expense'),
            state.annualIncExp,
            onChangeAnnualIncExp,
            ['Income', 'Expense']
          )}
          {renderInputView(t('Amount'), state.annualIncExpAmt, onChangeAnnualIncExpAmt, true)}
        </div>
        <div className='insm_inputsRowView'>
          {renderInputView(t('Existing Loan Amount Outstanding ( if any)'), state.loanAmt, onChangeLoanAmt, true)}
          {renderInputView(t('Existing Insurance Amount ( if any)'), state.lifeInsAmt, onChangeLifeInsAmt, true)}
        </div>
        <div className='insm_inputsRowView'>
          {renderSliderView()}
          {renderInputView(t('No. of Years the Insurance Coverage Required'), state.noYears, onChangeNoofYears, false, false, 'number')}
        </div>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='insm_buttonView'>
        <Button buttonName={t('Calculate')} onPressButton={onPressCalculate} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'insm_content'}>
        {renderHeaderView(t('Calculate Insurance Coverage'))}
        {renderInputsView()}
        {state.showResultModal && renderResultContent()}
        {renderButtonView()}
      </div>
    )
  }

  const renderResultContent = () => {
    const result = state?.resultObj?.life_cover_value;
    const value = result > state.lifeInsAmt ? Number(result - state.lifeInsAmt) : Number(state.lifeInsAmt - result);
    const displayValue = getFormatAmount(value)
    return (
      <div className={'insm_resultContent'}>
        <label className={"insm_resultStyle"}>{`Your required life cover amount is  ₹ ${displayValue}`}</label>
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      dialogClassName={'insm_container'}
      size={"lg"}
    >
      <div>
        {renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default InsuranceScoreModal;